/**
* Created by hansxing on 2018/12/17.
*/
<style lang="scss" scoped>
.quote-tool {

    .quote-tool-cont {
        margin-top: 10px;
        background: #fff;
        padding: 20px;
    }

    .quote-popover_time_btn {
        padding: 3px 0;
    }

    .tool-cont_item {
        display: flex;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        .tool-cont_item_title {
            width: 82px;
            font-size: 14px;
            height: 34px;
            line-height: 34px;
            font-weight: 600;
        }

        .item_table_result {
            /*width: 702px;*/
            border: 1px solid #ebeef5
        }

        .item_table_cont {
            display: flex;
            border-bottom: 1px solid #ebeef5;

            &:last-child {
                border-bottom: 0;
            }

            .tool-table-price {
                li {
                    border: 0;
                    padding: 0;
                }
            }

            li {
                /*text-align: center;*/
                line-height: 24px;
                padding: 5px 10px;
                font-size: 14px;
                /*border-right: 1px solid #ebeef5;*/
                /*float: left;*/
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    position: absolute;
                    background: #ebeef5;
                }

                &:last-child {
                    &:before {
                        width: 0;
                        background: transparent;
                    }
                }

                .tool-table-price {
                    li {
                        &:before {
                            width: 0;
                            background: transparent;
                        }
                    }
                }
            }

            .item_table_w0 {
                width: 75px;
            }

            .item_table_w1 {
                width: 105px;
            }

            .item_table_w2 {
                width: 90px;
            }

            .item_table_w3 {
                width: 65px;
            }

            .item_table_w4 {
                width: 55px;
            }

            .item_table_w5 {
                width: 55px;
            }

            .item_table_w6 {
                width: 90px;
            }

            .item_table_w7 {
                width: 100px;

            }

            .item_table_w8 {
                width: 140px;
                align-items: center;
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                word-break: break-all;
            }

            .item_table_w9 {
                width: 400px;
            }

            .item_table_w10 {
                width: 160px;

            }

        }

        .color-blue {
            color: #1989FA;
        }

        .color-red {
            color: #FF3B30;
        }
    }

    .table_cont-default {
        text-align: center;
        padding: 15px 0;
        font-size: 14px;
        color: #909399;
    }

    .tool-table-price {
        /*padding-left: 5px;*/
        li {
            display: flex;
            box-sizing: border-box;
            text-overflow: ellipsis;
            vertical-align: middle;
        }

        .table-price-l {
            width: 180px;
        }

        .table-price-r {
            width: calc(100% - 180px);
        }
    }

    .table-centered {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        /*padding: 0 5px;*/
        p {
            width: 100%;
            text-align: left;
        }
    }
}

.quote-tool /deep/ {
    .el-form-item__error {
        padding-top: 0;
    }

    .common-list-search {
        .el-form-item__content {
            width: auto;
        }

        .list-search-before {

            width: 100%;
        }

        .before-label-title {
            .el-form-item__content {
                width: calc(100% - 54px);
            }
        }

        .before-label-title_t, .before-label-title_th {
            display: block;

            .el-form-item__content {
                width: calc(100% - 92px);
            }
        }

        .before-label-title_th {
            .el-form-item__content {
                height: 34px;
            }

            .box-value_picker_date {
                width: 144px;
                display: inline-block;
                margin-right: 5px;
            }

            .box-value_picker_time {
                width: 80px;
                display: inline-block;
            }

        }

        .list-search-after {
            width: 180px;
            text-align: right;
            position: absolute;
            right: 60px;
        }
    }

    .tool-cont_item {
        .el-table--border, .el-table--group {
            border-radius: 4px;
        }

        .el-table td, .el-table th {
            padding: 5px 0;
        }
    }

}

.city-site-list {
    li {
        line-height: 26px;
        background: #ECF6FF;
        border-radius: 3.84px;
        display: inline-block;
        float: left;
        margin-right: 10px;
        margin-top: 10px;
        font-size: 12px;
        color: #319BF7;
        padding: 0 8px;
        cursor: pointer;

        &.site-list-selected {
            background: #319BF7;
            color: #FFFFFF;
        }

    }
}

.null-site {
    line-height: 26px;
    background: #f5f5f5;
    border-radius: 3.84px;
    color: #999;
    padding: 4px 8px;
    font-size: 12px;
}


</style>
<style lang="scss">
.quote-popover_time {
    .time-table .time-table-title {
        margin-left: 0 !important;
    }
}
</style>
<template>

    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont quote-tool">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form ref="form" :model="form" :rules="searchRules" label-width="100px">
                            <el-form-item label="报价类型：">
                                <el-select v-model="form.isCharteredCar">
                                    <el-option v-for="(item,index) in types"
                                               :value="index"
                                               :label="item"
                                               :key="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form :inline="true" label-width="95px">
                                <el-form-item label="渠道：">
                                    <el-select v-model="form.quoteChannel">
                                        <el-option v-for="item in channel"
                                                   :value="item.value"
                                                   :label="item.label"
                                                   :key="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="城市：">
                                    <el-select v-model="form.cityId"
                                               @change="selectCity"
                                               filterable
                                               placeholder="填写城市">
                                        <el-option
                                            v-for="city in serverCityList"
                                            :key="city.shortCode"
                                            :label="city.name"
                                            :value="city.shortCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="下车城市：" v-if="form.isCharteredCar==1">
                                    <el-select v-model="form.destinationCityId"
                                               filterable
                                               placeholder="填写城市">
                                        <el-option
                                            v-for="city in serverCityList"
                                            :key="city.shortCode"
                                            :label="city.name"
                                            :value="city.shortCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="类型：" v-if="form.isCharteredCar==0">
                                    <el-select v-model="form.ruleType" placeholder="请选择类型" @change="selectForm">
                                        <el-option
                                            v-for="item in ruleTypeOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="车型：">
                                    <seat-model v-model="form.vehicleType"></seat-model>
                                </el-form-item>
                                <el-form-item label="包车天数：" v-if="form.isCharteredCar==1">
                                    <el-select v-model="form.charteredCarDuration" placeholder="请选择包车天数">
                                        <el-option label="半日包车" value="999"></el-option>
                                        <el-option
                                            v-for="(item,idx) in 10"
                                            :key="idx"
                                            :label="idx+1"
                                            :value="idx+1">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <el-form-item label="上车地点：" prop="getOnPlace"
                                          v-if="form.isCharteredCar==0"
                                          class="before-label-title_t">
                                <search-address
                                    :is-slot="isSlotOnPlace"
                                    :disabled="onPlaceDisabled"
                                    :address="form.getOnPlace"
                                    input-width="640px"
                                    placeholder="填写上车地点"
                                    @update:searchAddress="getOnPlace"
                                    ref="clearGetOn"
                                ></search-address>
                                <ul class="city-site-list clear" v-if="stationList && stationList.length > 0">
                                    <li v-for="(s,index) in stationList"
                                        :class="{'site-list-selected': citySiteOnId == s.id && form.getOnPlace.address}"
                                        :key="index"
                                        @click="handleCitySite(s, 1)">{{ s.stationName }}
                                    </li>
                                </ul>
                                <p v-else><span class="null-site">无可选机场或站点</span></p>
                            </el-form-item>
                            <el-form-item label="下车地点：" prop="getOffPlace"
                                          v-if="form.isCharteredCar==0"
                                          class="before-label-title_t">
                                <search-address
                                    :is-slot="isSlotOffPlace"
                                    :disabled="offPlaceDisabled"
                                    :address="form.getOffPlace"
                                    input-width="640px"
                                    placeholder="填写下车地点"
                                    @update:searchAddress="getOffPlace"
                                    ref="clearGetOff"
                                ></search-address>
                                <ul class="city-site-list clear" v-if="stationList1 && stationList1.length > 0">
                                    <li v-for="(s,index) in stationList1"
                                        :class="{'site-list-selected': citySiteOffId == s.id && form.getOffPlace.address}"
                                        :key="index"
                                        @click="handleCitySite(s, 2)">{{ s.stationName }}
                                    </li>
                                </ul>
                                <p v-else><span class="null-site">无可选机场或站点</span></p>
                            </el-form-item>
                            <el-row>
                                <el-col :span="7">
                                    <el-form-item label="用车时间：">
                                        <div style="display: flex;width: 200px;justify-content: space-between">
                                            <car-date height-box="32"
                                                      width="160px"
                                                      is-title
                                                      is-icon-line
                                                      placeholder="选择用车日期"
                                                      :defaultDate="form.carDate"
                                                      @onUpdate="carDateValue"></car-date>
                                            <car-time height-box="32"
                                                      is-title
                                                      width="100px"
                                                      is-icon-line
                                                      placeholder="选择用车时间"
                                                      :defaultHour="deTimeH"
                                                      :defaultMinute="deTimeM"
                                                      @onUpdate="carTimeValue"></car-time>
                                        </div>

                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="用车公里数(公里）：" label-width="150px">
                                        <el-input style="width: 200px" type="number" size="small"
                                                  placeholder="用车公里数"
                                                  v-model="form.ctripEstimateDistance" clearable/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="用车时长(分钟)：" label-width="140px">
                                        <el-input style="width: 200px" type="number" size="small" placeholder="用车时长"
                                                  v-model="form.ctripEstimateTime" clearable/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="resetForm()">重置</el-button>
                        <el-button type="primary" @click="search()">报价</el-button>
                    </div>
                </com-list-search>
                <div class="quote-tool-cont" v-if="resultTable && resultTable.length > 0">
                    <div class="tool-cont_item">
                        <p class="tool-cont_item_title">计算结果：</p>
                        <div class="item_table_result">
                            <ul class="clear item_table_cont">
                                <li class="item_table_w0 font-w6">报价类型</li>
                                <li class="item_table_w1 font-w6">预计用车公里数</li>
                                <li class="item_table_w2 font-w6">预计用车时长</li>
                                <li class="item_table_w3 font-w6">报价</li>
                                <li class="item_table_w4 font-w6">渠道费</li>
                                <li class="item_table_w5 font-w6">平台费</li>
                                <li class="item_table_w6 font-w6">供应商管理费</li>
                                <li class="item_table_w6 font-w6">司机到手价</li>
                                <li class="item_table_w10 font-w6">下单</li>
                            </ul>
                            <ul class="clear item_table_cont" v-for="(item,index) in resultTable" :key="index">
                                <li class="item_table_w0">
                                    <span class="color-blue" v-if="item.quoteRuleDto.quoteType === 0">按里程报价</span>
                                    <span class="color-blue" v-if="item.quoteRuleDto.quoteType === 1">一口价</span>
                                </li>
                                <li class="item_table_w1">
                                    <span class="color-blue">{{ item.distanceText }}</span>
                                </li>
                                <li class="item_table_w2">
                                    <span class="color-blue">{{ item.timeText }}</span>
                                    <!--<span class="color-blue">{{item.timeValue}}</span>-->
                                </li>
                                <li class="item_table_w3">
                                    <span class="color-red">{{ item.quotePrice + '元' }}</span>
                                </li>
                                <li class="item_table_w4">
                                    <span class="color-red">{{ item.quoteChannelPrice + '元' }}</span>
                                </li>
                                <li class="item_table_w5">
                                    <span class="color-red">{{ item.quotePlatformPrice + '元' }}</span>
                                </li>
                                <li class="item_table_w6">
                                    <span class="color-red">{{ item.quoteSupplierPrice + '元' }}</span>
                                </li>
                                <li class="item_table_w6">
                                    <span class="color-red">{{ item.driverPrice + '元' }}</span>
                                </li>
                                <li class="item_table_w10">
                                    <el-popover
                                        placement="bottom"
                                        title="报价详情"
                                        width="200"
                                        trigger="click">
                                        <div v-html="item.quoteLog"></div>
                                        <el-button
                                            slot="reference"
                                            type="text"
                                            style="padding: 0">
                                            报价详情
                                        </el-button>
                                    </el-popover>
                                    <el-button type="text" style="padding: 0;margin-left: 10px"
                                               @click="handleOrderQuo(form, item)">
                                        按报价下单
                                    </el-button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tool-cont_item">
                        <p class="tool-cont_item_title">报价规则：</p>
                        <div class="item_table_result">
                            <ul class="clear item_table_cont">
                                <li class="item_table_w7 font-w6">规则编号</li>
                                <li class="item_table_w8 font-w6">规则名称</li>
                                <li class="item_table_w9 font-w6">价格-价格参数</li>
                            </ul>
                            <ul class="clear item_table_cont" v-for="(item1,idx) in resultTable" :key="idx">
                                <li class="item_table_w7">
                                    <div class="table-centered">
                                        <p>{{ item1.quoteRuleDto.ruleNo }}</p>
                                    </div>
                                </li>
                                <li class="item_table_w8">
                                    <div class="table-centered">
                                        <p>{{ item1.quoteRuleDto.ruleName }}</p>
                                    </div>
                                </li>
                                <li class="item_table_w9">
                                    <ul class="tool-table-price" v-if="item1.quoteRuleDto.quoteType === 0">
                                        <li>
                                            <div class="table-price-l align-l">
                                                <p>基础价：{{ item1.quoteRuleDto.basicPrice }}元</p>
                                                <p>超公里价：{{ item1.quoteRuleDto.exceedDistancePrice }}元/公里</p>
                                                <p>超时长价：{{ item1.quoteRuleDto.exceedTimePrice }}元/分钟</p>
                                                <p>平峰价：{{ item1.quoteRuleDto.flatTimePrice }}元/公里</p>
                                                <p>高峰价：{{ item1.quoteRuleDto.peakTimePrice }}元/公里</p>
                                            </div>
                                            <div class="table-price-r align-l">
                                                <p>-</p>
                                                <p>基础公里数：{{ item1.quoteRuleDto.basicDistance }}公里</p>
                                                <p>基础时长：{{ item1.quoteRuleDto.basicTime }}分钟</p>
                                                <p>
                                                    <el-popover
                                                        placement="right"
                                                        width="390"
                                                        popper-class="quote-popover_time"
                                                        trigger="click">
                                                        <time-table :exhibition="false"
                                                                    :peak-time="item1.quoteRuleDto.peakPeriod"
                                                                    :night-time="item1.quoteRuleDto.nightPeriod"></time-table>
                                                        <el-button slot="reference" class="quote-popover_time_btn"
                                                                   type="text">时段表
                                                        </el-button>
                                                    </el-popover>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="table-price-l align-l">
                                                <p>夜间费：{{ item1.quoteRuleDto.nightFee }}元/公里</p>
                                                <p>等候费：{{ item1.quoteRuleDto.waitingFee }}元/分钟</p>
                                                <p>回空里程费：{{ item1.quoteRuleDto.exceedBackPrice }}元/公里</p>
                                                <div v-if="item1.quoteRuleDto.longDistanceDtoList">
                                                    <p v-for="(vm,index) in item1.quoteRuleDto.longDistanceDtoList"
                                                       :key="index">
                                                        长途费{{ index + 1 }}：{{ vm.longDistancePrice }}元/公里
                                                    </p>
                                                </div>
                                                <p v-else>长途费：{{ item1.quoteRuleDto.longDistancePrice }}元/公里</p>
                                            </div>
                                            <div class="table-price-r align-l">
                                                <p>&nbsp;</p>
                                                <p>-</p>
                                                <p>超出公里数：{{ item1.quoteRuleDto.allowBackDistance }}公里</p>
                                                <div v-if="item1.quoteRuleDto.longDistanceDtoList">
                                                    <p v-for="(vm,index) in item1.quoteRuleDto.longDistanceDtoList"
                                                       :key="index">
                                                        超出公里数：{{ vm.longDistance }}公里
                                                    </p>
                                                </div>
                                                <p v-else>
                                                    超出公里数：{{ item1.quoteRuleDto.longDistance }}公里</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="tool-table-price" v-if="item1.quoteRuleDto.quoteType === 1">
                                        <li>
                                            <div class="">
                                                <p>白天一口价：{{ item1.quoteRuleDto.daytimeFixedPrice }}元</p>
                                                <p>白天时段：{{ item1.quoteRuleDto.daytimePeriod.daytimePeriodBegin }} -
                                                    {{ item1.quoteRuleDto.daytimePeriod.daytimePeriodEnd }}</p>
                                                <p>夜间一口价：{{ item1.quoteRuleDto.nightFixedPrice }}元</p>
                                                <p>夜间时段：{{
                                                        item1.quoteRuleDto.nightPeriodFixedPrice.nightPeriodBegin
                                                    }}
                                                    -
                                                    次日{{
                                                        item1.quoteRuleDto.nightPeriodFixedPrice.nightPeriodEnd
                                                    }}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
                <div class="quote-tool-cont" v-else>
                    <p class="table_cont-default" v-if="!resultTable">操作提示：1、请填写报价参数
                        2、点击“报价”按钮，查看报价结果</p>
                    <p class="table_cont-default" v-else>所填参数暂无报价结果。换个参数试试，或联系管理员配置</p>
                </div>
                <extra-cost v-if="form.isCharteredCar===1&&resultTable&&resultTable.length > 0"
                            :info="resultTable[0]"
                            :useCarTime="`${form.carDate} ${form.carTime}:00`"></extra-cost>
            </div>
        </el-main>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '../../../components/comListSearch/index.vue'
import carDate from '@/components/datePickerWithTitle/index.vue'
import carTime from '@/components/timePickerWithTitle/index.vue'
import SearchAddress from '@/components/searchAddress/index.vue'
import timeTable from '../components/timeTable/index';
import {getCityData} from '@/urls/index';
import SeatModel from "@/components/seatModel/index";
import {channelList} from '@/data/index'
import ExtraCost from './extra-cost.vue'

export default {
    name: 'quoteTool',
    //定义模版数据
    data() {
        const oneDayTime = 86400000;
        let tomorrowDate = this.filterDate(new Date(+new Date() + oneDayTime), 'yyyy/MM/dd');  // 明天
        return {
            types: ['接送服务报价', '包车报价'],
            deTimeH: "09",
            deTimeM: "00",
            regionSelect: null,
            showHideCity: false,
            form: {
                isCharteredCar: 0,
                ruleType: '12',  // 全部类型
                cityId: '3101',  // 城市
                // saddle: '1',  // 全部车座
                vehicleType: 1,  // 全部车型
                getOnPlace: {
                    city: "上海市",
                    title: ""
                },  // 上车地点
                getOffPlace: {
                    city: "上海市",
                    title: ""
                },  // 下车地点
                carDate: tomorrowDate,  // 用车日期
                carTime: '09:00',  // 用车时间
                quoteChannel: null,
                stationId: '',
                charteredCarDuration: 1,
                destinationCityId: '3101',
                ctripEstimateDistance: '',
                ctripEstimateTime: ''
            },
            ruleTypeOptions: [
                {label: '接机', value: '7',},
                {label: '送机', value: '8',},
                {label: '接站', value: '9',},
                {label: '送站', value: '10',},
                {label: '实时用车', value: '12'},
                {label: '接船', value: '27'},
                {label: '送船', value: '28'},
            ],
            resultTable: [],
            searchRules: {
                getOnPlace: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("上车地点不能为空");
                        else {
                            if (!this.form.getOnPlace.value)
                                callback("请选择正确的上车地点");
                            else callback()
                        }
                    }, required: true, trigger: 'blur'
                }],
                getOffPlace: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("下车地点不能为空");
                        else {
                            if (!this.form.getOffPlace.value)
                                callback("请选择正确的下车地点");
                            else callback()
                        }
                    }, required: true, trigger: 'blur'
                }],
                stationId: [{required: true, message: '请选择地点', trigger: 'change'},]
            },
            serverCityList: [],
            stationList: [],
            stationList1: [],
            channel: channelList,//渠道
            isSlotOnPlace: false,
            onPlaceDisabled: false,
            citySiteOnId: '',
            isSlotOffPlace: true,
            offPlaceDisabled: false,
            citySiteOffId: '',
        }
    },
    components: {
        comListSearch,
        carDate, carTime,
        timeTable, SeatModel,
        ExtraCost,
        SearchAddress
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        this.getChannelData()
        this.getServerCity();
        this.getStation(this.form.cityId, 1);
        this.getStation(this.form.cityId, 2);
    },
    //定义事件方法
    methods: {
        getChannelData() {
            this.form.quoteChannel = this.channel[1].value
        },
        getQuoteCity(city) {
            this.form.city = city;
            this.regionSelect = city;
        },
        hideCitySel(b) {
            this.showHideCity = b
        },
        getOnPlace(val) {  // 上车地点
            this.form.getOnPlace = val;
            if (val.value)
                this.$refs["form"].clearValidate("getOnPlace");
            let cityInfo = this.serverCityList.find(c => (val.city == c.city)) || {};
            if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 1)
        },
        getOffPlace(val) {  // 下车地点
            this.form.getOffPlace = val;
            if (val.value)
                this.$refs["form"].clearValidate("getOffPlace");
            let cityInfo = this.serverCityList.find(c => (val.city == c.city)) || {};
            if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 2)
        },
        carDateValue(data) {  // 用车日期
            this.form.carDate = data
        },
        carTimeValue(data) {  // 用车时间
            this.form.carTime = data
        },
        search() {
            this.loadingShow();
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    this.loadingShow();
                    let {
                            isCharteredCar,
                            quoteChannel,
                            vehicleType,
                            cityId,
                            getOffPlace,
                            getOnPlace,
                            ruleType,
                            carDate,
                            carTime,
                            charteredCarDuration,
                            destinationCityId,
                            ctripEstimateDistance,
                            ctripEstimateTime
                        } = this.form,
                        form = {
                            isCharteredCar,
                            quoteChannel,
                            vehicleType,
                            cityId,
                            type: ruleType, //  产品类型
                            useCarTime: carTime ? +new Date(`${carDate} ${carTime}`) : undefined
                        };
                    if (isCharteredCar == 1) {
                        form.charteredCarDuration = charteredCarDuration;
                        form.destinationCityId = destinationCityId;
                    }

                    if (ctripEstimateTime)
                        form.ctripEstimateTime = ctripEstimateTime * 60;
                    if (ctripEstimateDistance)
                        form.ctripEstimateDistance = ctripEstimateDistance * 1000;

                    if (getOffPlace && getOffPlace.value) {
                        form.destinationsLong = getOffPlace.location.split(',')[1];//  下车地点维度 ,
                        form.destinationsLat = getOffPlace.location.split(',')[0];//  下车地点经度 ,
                        form.destinationsName = getOffPlace.address;//  下车地点名称
                        //如果有站点信息 《接》的服务 站点信息为上车站点信息
                        if (getOffPlace.stationCode) {
                            form.stationCode = getOffPlace.stationCode;
                            form.stationName = getOffPlace.stationName;
                        }
                    }
                    if (getOnPlace && getOnPlace.value) {
                        form.originsLong = getOnPlace.location.split(',')[0];//  上车地点维度
                        form.originsLat = getOnPlace.location.split(',')[1];//  上车地点经度
                        form.originsName = getOnPlace.address;//  上车地点名称

                        //如果有站点信息 《送》的服务 站点信息为下车站点信息
                        if (getOnPlace.stationCode && (ruleType === '7' || ruleType === '9')) {
                            form.stationCode = getOnPlace.stationCode;
                            form.stationName = getOnPlace.stationName;
                        }
                    }

                    //接口定义 经纬度反了 需要倒一下
                    const latitude = form.originsLat;  //  c_b 自定义经纬度 反传
                    form.originsLat = form.originsLong;//  上车地点经度 ,
                    form.originsLong = latitude;//  上车地点维度 ,

                    let res = await this.http('/galaxyQuote/calculateQuote', form, 'POST');
                    if (res.success) {
                        let data = res.data
                        data.forEach(item => {
                            item.quoteLog = item.quoteLog ? item.quoteLog.replace(/\+/g, "<br>") : ""
                        })
                        this.resultTable = data;
                    }
                    this.loadingHide();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            this.loadingHide();
        },
        resetForm() {
            this.$refs['form'].resetFields();
            this.$refs['clearGetOn'].clearAddressMod();
            this.$refs['clearGetOff'].clearAddressMod();
            this.resultTable = '';
        },
        resetSelectInit() {
            this.$refs['form'].resetFields();
            if (this.form.getOnPlace && this.form.getOnPlace.value)
                this.$refs['clearGetOn'].clearAddressMod();
            if (this.form.getOffPlace && this.form.getOffPlace.value)
                this.$refs['clearGetOff'].clearAddressMod();
        },
        selectForm(val) {
            let cityInfo = this.serverCityList.find(c => this.form.cityId === c.shortCode);
            this.resetSelectInit()
            this.form.getOnPlace = {city: cityInfo.city};  // 上车地点
            this.form.getOffPlace = {city: cityInfo.city};  // 下车地点
            switch (parseInt(val)) {
                case 7:
                case 9:
                case 12:
                case 27:
                    this.offPlaceInit(cityInfo)
                    break;
                case 8:
                case 10:
                case 28:
                    this.onPlaceInit(cityInfo)
                    break;
            }
        },
        selectCity(val) {
            let cityInfo = this.serverCityList.find(c => val === c.shortCode);
            this.regionSelect = cityInfo.name;
            this.resetSelectInit();
            this.form.getOnPlace = {city: cityInfo.city};  // 上车地点
            this.form.getOffPlace = {city: cityInfo.city};  // 下车地点
            switch (parseInt(this.form.ruleType)) {
                case 7:
                case 9:
                case 12:
                case 27:
                    this.offPlaceInit(cityInfo)
                    this.onPlaceDisabled = false;
                    break;
                case 8:
                case 10:
                case 28:
                    this.onPlaceInit(cityInfo)
                    break;
            }
        },
        onPlaceInit(cityInfo) {
            this.isSlotOnPlace = true;
            this.isSlotOffPlace = false;
            this.onPlaceDisabled = false;
            this.offPlaceDisabled = true;
            if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 2);
            this.getOnPlace(this.form.getOnPlace);
        },  // 上车地点
        offPlaceInit(cityInfo) {
            this.onPlaceDisabled = true;
            this.offPlaceDisabled = false;
            this.isSlotOnPlace = false;
            this.isSlotOffPlace = true;
            if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 1);
            this.getOffPlace(this.form.getOffPlace)
        },  // 下车地点
        async getStation(id, v) {
            let form = {
                shortCode: id,
                stationType: 2
            }
            let type = this.form.ruleType;
            if (type === '9' || type === '10') form.stationType = 1;
            if (type === '12') delete form.stationType;
            if(['27','28'].includes(type))
                form.stationType=3;
            let res = await this.http('/galaxyStationApi/doStationList', form, 'POST');
            if (res.success) {
                switch (v) {
                    case 1:
                        this.stationList = res.data;
                        break;
                    case 2:
                        this.stationList1 = res.data;
                        break
                }
            }
        },
        async getServerCity() {
            const ret = await getCityData(this, '2');
            // console.log(ret, '================');
            // return ret;
            if (ret)
                this.serverCityList = ret;
        },
        selectStation() {
        },
        handleOrderQuo(info, item) {
            let form = info;
            form.orderAmount = item.quotePrice;  // 订单金额
            form.setAmount = parseInt(item.quoteSupplierPrice) + parseInt(item.driverPrice);  // 结算金额
            form.citySiteOnId = this.citySiteOnId;
            form.citySiteOffId = this.citySiteOffId;
            this.$router.push({
                name: 'demand',
                query: {
                    orderInfo: encodeURI(JSON.stringify(info))
                }
            });

        },
        handleCitySite({id, stationName, cityName, longitude, latitude, airportCode}, i) {
            const obj = {
                stationCode: airportCode,
                stationName,
                address: stationName,
                city: cityName,
                location: longitude + ',' + latitude,
                title: stationName,
                value: stationName,
            };
            switch (i) {
                case 1:
                    this.$refs["form"].clearValidate("getOnPlace");
                    this.citySiteOnId = id;
                    this.form.getOnPlace = obj
                    break;
                case 2:
                    this.$refs["form"].clearValidate("getOffPlace");
                    this.citySiteOffId = id;
                    this.form.getOffPlace = obj
                    break;
            }

        }  // 上车地点
    },
    //监听模版变量
    watch: {}

}
</script>
